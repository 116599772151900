body {
	/*overflow-x: hidden;*/
	background: #000;
	background-image: url('/assets/img/crm/background_dark.jpg');
    background-repeat: repeat-y;
    background-position: top center;
    background-size: 100% auto;

	padding: 0;
	color: white;
	/*font-size: 1.1vw;*/

}

.parrafo {
  white-space: pre-line;
}



.wrapper, .wrapper .inner, section {
	width: auto !important;
}
.wrapper, .wrapper .inner {
	position: relative;

	}

section {
    min-height: auto;
}

h3 {
	width: 100%;
	margin: 2em 0 1em 0;
	padding: 0;
	border: 0;
	text-align: center;
	font-size: 1.5em;
}

.help h3 { text-align: left; }
.central h3 {
    margin: 0;
    padding-bottom: 1vw;
}

li {
    list-style: none;
    padding: 0;
}

section.main {
    min-height: calc(100vh - 150px);
}



section.central/*, .neonbg1, .neonbg2*/ {
    display: flex;
    justify-content: space-around;
    width: 100vw;
    height: auto;
    margin: auto;
    max-width: calc(1180px - 4vw);
}

section.central > div {
	/*width: calc(30% - 4vw) !important;*/
	height: auto;
	padding: 0;
    width: 30vw;
}

section.central > div.help.do-this {
   /*background: red;*/
   color: black;
    width: 100%;
    max-width: 700px;
    height: 560px;
    padding: 0;
    position: relative;
    margin-left: -3%;
}

.lang-selector-container {
    top: 10px;
    right: 0;
    position: absolute;
    z-index: 1;



    &.selected {
        right: clamp(22px,2.3vw,33px);
        ul {
            display: block;
        }
    }
    ul {
        display: none;

        li {
            background-image: url(/assets/img/crm/lang_background.gif);
            width: 100%;
            height: clamp(30px,2.5vw,40px);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: black;
            align-items: center;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
    }
    .lang-selector {
        border: 4px solid black;
        background-color: #ff9100;
        display: inline-flex;
        box-shadow: 0 0 15px #ffd05390, 0 0 10px #ffd05330;

    
        .lang {
            background-image: url(/assets/img/crm/lang_background.gif);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: black;
            align-items: center;
            display: flex;
            justify-content: center;
            width: clamp(50px,4.5vw,73px);
            height: clamp(30px,2.5vw,40px);
        }

        .btn-down {
            
            &.selected {
                display: none;
            }
            cursor: pointer;
            width: clamp(22px,2.3vw,33px);
            height: clamp(30px,2.5vw,40px);
            align-items: center;
            display: flex;
            justify-content: center;
            
            .dropdownimg {
                background-image: url(/assets/img/crm/lang_arrow.gif);
                width: 100%;
                height: 17px;
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    
    }
}



section.central > div.help.do-this .lights  {
    background-image: url(/assets/img/crm/bg-lights-desktop.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /*background-color: green;*/
    width: 100%;
    height: 90%;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 45%;
    left: 50%;
    padding: 24% 20% 10% 18%;
    font-family: 'FuturaMdCnBT',sans-serif;

    h2 {
      text-transform: uppercase;
      font-family: 'FuturaBTBoldCondensed',sans-serif;
      font-size: clamp(35px, 3vw, 38px);
      white-space: nowrap;
    }
}

.dont-do-this {
    /*color: white;*/
    width: 100%;
    text-align: left;
    position: absolute;
    /*bottom: -2%;*/
    bottom: 15%;
    line-height: 1.1em;
    height: 10%;
    padding: 0px 35% 0 22%;
}

.page-home.wrapper {
        background: none !important;
}

.help li {
  display: flex;
  align-self: center;
  align-items: self-start;
  color: red;
  font-size: clamp(28px, 3vw, 30px);
  line-height: 0.9em;
    margin-bottom: 0.5vw;

        &:last-child {
        margin-top: 0.5em;
        }

      > div {
      font-size: 1.1em;
      /*width: 1em;*/
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-right: 0.3em;
      }

      strong {
            color: black;
        }
}





.hr {
position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    height: 15px;
    border-top: 1px solid rgba(255,255,255,.2);
    margin: 0 -15px;
}



.text-muted {
    color: #888888 !important;
}

.form-check {
    padding-top: 1em !important;
}

input:focus-visible {
    outline: none;
}

section.slogan .title .title-1 {
    text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 20px #b62939, 0 0 30px #b62939, -1px -1px 2px #b62939;
    color: #fdfefa;
    font-family: "FuturaMdBT",cursive;
    font-family: "FuturaMdCnBT",sans-serif;
    text-transform: uppercase;
    font-size: clamp(30px,4vw,53px);
    font-weight: 500;
    /*text-align: calc(calc(center)-calc(5vw));*/
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-width: clamp(.1px,.1vw,1px);
    -webkit-text-stroke-color: #b62939;
    text-align: left;
    padding-left: 18.5vw;
}

.login {
    border-radius: 0px !important;
    text-align: center;
    transition: transform 0.5s, box-shadow 1s;
    min-height: 450px;
    max-width: 450px;


       & .sep.gold-light {
        height: 0px;
        /* border: none; */
        /* border-top-width: 5px !important; */
        border: none;
        border-top: 5px solid #e9ff72;
        width: 90%;

    }

    > .card-box-body {
        height: 100%;


        > .row {
            height: 50%;
            align-items: center;
        }
    }

    &.password-recovery {
        > .card-box-body {
            > .row {
                height: 100%;
            }
        }
    }

    .title.h4 {
        transform: translate(-50%,-100%);
        position: absolute;
        left: 50%;
        text-transform: uppercase;
        text-shadow: 0 0 5px #fff000, 0 0 15px #fff000;
        color: fdfefa;
        font-family: 'FuturaMdBT', cursive;
        text-align: center;
        -webkit-text-fill-color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-width: clamp(0.1px,0.1vw,1px);
        -webkit-text-stroke-color: #ffa500;
        white-space: nowrap;
        font-size: clamp(1.2rem, 1.4vw, 1.3rem)
    }
    
    
}


input[type="text"], input[type="button"], input[type="password"] {
	/*background: #7d7d7d;*/
	color: #333;
}


 input[type="button"], input[type="submit"] {
    font-size: 0.9em;
    font-family: "FuturaMdCnBT", sans-serif;
}

.extra.container {
   /* max-width: 1800px; */
    max-width: 100%;
    padding: 0;

}

.page-home h3 {

text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 20px #b62939, 0 0 30px #b62939, -1px -1px 2px #b62939;
  color: #fdfefa;
  font-family: 'FuturaMdBT', cursive;
    font-family: 'FuturaMdCnBT', sans-serif;
    text-transform: uppercase;
    font-size: clamp(30px,4vw,53px);
    font-weight: 500;
      text-align: center;
      /*letter-spacing: 1.5px;
      font-size: 2em;*/
//   -webkit-animation: blink 1s infinite;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-width: clamp(0.1px,0.1vw,1px);
    -webkit-text-stroke-color: #b62939;

    letter-spacing: 1px;
    margin-top: 1.5em;
    margin-bottom: 0.5em;


}

.slick-slide {
  outline: none;
  margin: 0 0px;
  position: relative;
}

.slider {
  margin: -20px auto 0 auto;
}

.slider .slick-slide {
transform: translate(300px,0);
}

@media screen and (max-width: 1600px)  {
    .slider .slick-slide {
    transform: translate(250px,0);
    }
}

@media screen and (max-width: 1500px)  {
    .slider .slick-slide {
    transform: translate(200px,0);
    }
}


@media screen and (max-width: 1400px)  {
    .slider .slick-slide {
    transform: translate(150px,0);
    }
}

@media screen and (max-width: 1300px)  {
    .slider .slick-slide {
    transform: translate(100px,0);
    }
}

@media screen and (max-width: 1024px)  {
    .slider .slick-slide {
    transform: translate(50px,0);
    }
    .slick-prev, .slick-next {
        padding: 0 30px !important;
    }
}


.slick-slide > div {
    padding: 20px;
}

    .slider div img {
        width: 100%;
        /* margin: 20px;
        margin-left: 0px;*/
    }

.slider * {
    transition: opacity 1s;
}

.slider .slick-slide {
    opacity: 0.2;
}

.slider .slick-current
{opacity: 1;
}

.slider .slick-current img
{
box-shadow: 0px 0px 15px #34999e;
}

.slick-slide h4 {
    position: absolute;
    z-index: 17;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    display: table;
    /* width: 50%; */
    display: inline-table;
    width: auto;
    padding: 10px 20px;
    transition: all 500ms ease-in-out;
    opacity: 0;
    border: 1px solid #34999e;
    background-color: #34999e90;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.7);
    text-align: center;
    font-size: clamp(10px,2.5vw,35px);
}




.slick-current h4 {
    top: calc(50% - 70px);
     transform: translate(-50%,-50%);
    opacity: 1;
}

    .slick-dots li button:before {
    color: rgba(23, 226, 23,0.5);
    font-size: 8px;
}

.slick-dots li.slick-active button:before {
    color: rgb(23, 226, 23);
}

.slick-prev, .slick-next {
    height: 100%;
    padding: 0 50px;
    position: absolute; z-index: 9999;
   /* background: red;*/

}


.slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: #34999e
}

.slick-prev:before {
    margin-left: -15px;
}

.slick-next:before {
    margin-left: -15px;
}


.slick-prev { left: 5px;}
.slick-next { right: 0px;}

.slick-list {
    padding: 0 !important;
}


.message {
    top: -100%;
	position: absolute;
	opacity: 0;
	transition: opacity 1s;
}

.login_data {
    display: flex;
    /* width: calc(100% - 4vw); */
    justify-content: center;
    /* padding: 0 10vw; */
}


/*

.login_data input {
	width: 25%;
}
*/

.block {
    padding: 2vw 0;
    /* display: block; */
    /* overflow: hidden; */
    transition: opacity 0.5s;
}
/*
.block:last-child {
    padding: 20px 0 0 0;
}
*/
.info {
    color: #777;
    font-size: 15px;
    margin: 15px 30px 30px 30px;
    font-size: 0.8em;
}

strong.subtitle {
    margin-bottom: 10px;
    display: block;
    font-size: 1.3em;
}


strong.green {color: #00af00;font-size: 2.5vh;}

.guest.block small {
    margin-top: 5vh;
    margin-bottom: 1.5vh;
    margin-bottom: 1.5vh;
    display:block;
    font-size: 1.9vh;
}


small {
	display:block;
}
.returning_user {
    margin-top: 15px;
    /*font-size: 14px;*/
    color: #666;
    font-size: 0.8em;
}

input[type="checkbox"] {
    margin-right: 10px;
    background: #555;
}



.font-bold {
    font-family: "FuturaBTBoldCondensed", sans-serif !important;
    font-size: 1.4em !important;
}

.font-medium {
    font-family: "FuturaMdCnBT", sans-serif !important;
    font-size: 1.2em;
}

.font-cond-book {
    font-family: "FuturaPTCondBook", sans-serif !important;
}



.tos_accept {
    
    /* text-align: left; */
    margin-bottom: 15px;
    font-size: 0.7em;
}

.new_data , .recovery_data {
    display: flex;
    justify-content: center;
    /* background: red; */
}

section.slogan {

  
    .title {
        text-align: center;
        min-height: clamp(100px, 10vw, 160px) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title-1 {
          text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 20px #b62939, 0 0 30px #b62939, -1px -1px 2px #b62939;
          color: #fdfefa;
          font-family: 'FuturaMdBT', cursive;
            font-family: 'FuturaMdCnBT', sans-serif;
            text-transform: uppercase;
            font-size: clamp(30px,4vw,53px);
            font-weight: 500;

              /*letter-spacing: 1.5px;
              font-size: 2em;*/
        //   -webkit-animation: blink 1s infinite;
            -webkit-text-fill-color: white;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-width: clamp(0.1px,0.1vw,1px);
            -webkit-text-stroke-color: #b62939;
        }
  
        .title-2 {
          text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 20px #ffa500, 0 0 30px #ffa500, -1px -1px 2px #ffa500;
          color: #fdfefa;
          font-family: 'FuturaMdBT', cursive;
          text-align: right;
          letter-spacing: 1.5px;
          font-size: 2em;
          padding-right: 11.5vw;
        -webkit-text-fill-color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-width: clamp(0.1px,0.1vw,1px);
        -webkit-text-stroke-color: #ffa500;
            margin-top: -0.5em;
        }




    }
  
}

/*@media screen and (min-width: 801px) {
    .page-home {

        section.slogan {
            .title-1 {
                text-align: center;
            }

            .title-2 {
              padding-right: 12vw;
            }
        }
    }
}*/
  
section.reasons {
    position: relative;
    display: block;
    margin: 4em 0;
}


.reasons:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    display: block;
    position: absolute;
    left: 0;
    bottom: 32%;
    height: 75%;
    width: 75%;
    transform: translate(15%,50%);
    filter: blur(50px);
    border-radius: 50%;
    overflow: hidden;
    z-index: -2;
}

.reasons-inner {

    max-width: calc(1180px - 4vw);
    margin: auto;
    padding: 0;
    align-items: center;
    position: relative;
    width: 70%;
    transform: translate(5%,0);
}

.poker-hand {
   width: 50%;
    /* height: 100%; */
    display: block;
    position: absolute;
    margin-left: -38%;
    height: 38vw;
    height: 435px;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
}

.poker-hand:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background-image: url('/assets/img/crm/hands.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% auto;

}

.benefits {

    margin-left: 12%;
    padding: 0 20px;
}



.benefits ul li {
    margin: 1.5vw 0;
    font-size: 1.2em;
    display: flex;
    align-items: center;

}

.benefits ul li span {
    line-height: 1.2em;
    font-family: 'Nunito',sans-serif;
    font-size: 0.95em;
}

.benefits ul li strong {
    font-size: 1.6em;
    color: #00fff6;
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
    text-shadow: 0 0 10px #00fff6;
    margin-right: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.2em;
}

	.screenshots .desktop {
		display: block;
	}
	.screenshots .mobile {
		display: none;
	}



@media screen and (max-width: 1200px)  {

	header {
    position: relative;
	}

}


@media screen and (max-width: 1024px) {
	section.central {
		/*display:flex;*/
		overflow: hidden;
		width: 96vw;
		margin: auto;
        flex-wrap: wrap;
        flex-direction: row;
        max-width: 750px;
	}

	section.central > div.help.do-this {

		width: 100%;
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 2.5vw;
        margin-left:0;

        .lights {
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            padding: 12vw 20% 10% 22%;

        }

	}

    .dont-do-this {
        top: 62%;
        color: black;
        padding: 0px 35% 0 22%;
        left: 0;
    }
	
    section.central > div.help.do-this .lights {
        background-image: url(/assets/img/crm/bg-lights-mobile.png);
    }


	section.central > div.login-box {
        flex: 1 0 100%;
         .button {
                min-width: auto;
            }
	}

	.login .block {
		padding: 2.5em 1em;
	}

	strong.subtitle {
		font-size: 1.25em;
	}
    .slick-current h4 {
            top: 50%;
        }
	

}



@media screen and (max-width: 1000px) and (min-width: 801px) {
    .logo {
		/*float: none;*/

    }
    .wrapper:not(.is-guest-user) section.slogan {
        .title {

            /*.title-1 {
                font-size: 2.8vw;
            }*/
            .title-2 {
                font-size: 1.8em;
            }
        }
    }

}

@media screen and (max-width: 800px)  {
	
    .help li {
     font-size: clamp(18px, 3.5vw, 28px);
    }

    section.central > div.help.do-this .lights h2 {
        font-size:clamp(15px,4.4vw,35px);
    }

	.screenshots .desktop {
		display: none;
	}
	.screenshots .mobile {
		display: block;
	}

	.swiper-button-next, .swiper-button-prev {
    padding: 0 0.5em;
	}


	.swiper-button-next:after,
	.swiper-button-prev:after {
	   font-size: calc(var(--swiper-navigation-size) / 1.5);
	   text-shadow: 2px 2px black;
	  
	}

	
	.logo {

		position: relative;
        height: 120px;
        background-position: center;
        width: 200px;
        margin: auto;
	}
	
	body:before {
		top: 70px;
	}

    section.slogan .title {
        justify-content: start;
        width: 100%;
        padding: 0 10vw;
        min-height: clamp(60px, 15vw, 115px) !important;
    }

}

@media screen and (max-width: 600px)  {
    section.central > div.help.do-this {

        /*.lights {
            padding-top: 12%;
        }*/
    }
    .dont-do-this {
        top: 87%;
        padding: 0 40% 0 22%;
        color: white;
        font-size: 12px;
    }

}



.registered.block {
    border-bottom: 1px solid #444;
    /* margin-bottom: 20px;*/
    position: relative;
}



.back {
    margin-top: 20px;
    background: #ffffffcc;
    display: inline-block;
    padding: 5px 20px;
    border-radius: 5px;
    color: black;
    cursor: pointer;
    font-size: 15px; 
}

.recover_password {
	display:none;
}


.complete .row {
    display: flex;
    vertical-align: middle;
    align-items: middle;
    text-align: mi;
    justify-items: center;
    align-items: center;
    width: 100%;
}

.complete .row label {
    width: 25%;
    padding: 10px;
    text-align: left;
}

.complete .row .input {
    width: 50%;
    padding: 10px;
}

.complete .row .info {
    width: 25%;
    text-align: left;
    /* padding: 10px; */
    /* white-space: nowrap; */
    margin: 0 0 0 20px;
}

.complete input.finish {
    font-size: 20px;
    /*font-family: 'Roboto Condensed', sans-serif;*/
    font-family: sans-serif;
    margin-top: 50px;
    border-radius: 10px;
	margin-bottom: -10px;
}








section.requirements {
    margin: 4em 0;
    /* background: blue; */
    display: block;
}

.requirements-inner {
    background: rgba(0,0,0,.6);
    position: relative;
}

.requirements-inner > div {
    display: flex;
    max-width: calc(1180px - 4vw);
    justify-content: space-between;
    text-align: center;
    margin: auto;
    flex-wrap: wrap;
    padding: 1em 2em 2em 2em;


}



.requirements-inner .two-cards {
    content: "";
    background: url(/assets/img/crm/2cards.png);
    width: 200px;
    height: 200px;
    display: block;
    position: absolute;
    transform: translate(0, -75%);
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 2;
    left: 0;
}


@media screen and (max-width: 1600px) {
    .requirements-inner {
        padding-bottom: clamp(50px,15vw,150px);
    }
	.requirements-inner .two-cards {
        left: 50%;
        transform: translate(-50%, -20%);
        width: clamp(100px,15vw,200px);
        background-size: contain;
        height: clamp(100px,15vw,200px);
    }
}

@media screen and (max-width: 640px) {
    .requirements-inner {
        padding-bottom: 0;
    }
	.requirements-inner .two-cards {
        display:none;
    }
}


.requirements-cell.image {
    display: inline-grid;
    justify-content: center;
    align-self: center;
}

.requirements-cell.browsers.image img {
    padding-bottom: 5%;
}

.requirements-cell.text {

    align-self: center;
    font-weight: normal;
    font-family: 'Nunito',sans-serif;
}

.requirements-cell:nth-child(1) {
    width: 25%;
    order: 1;
}

.requirements-cell:nth-child(2) {
    width: 25%;
    order: 5;
}

.requirements-cell:nth-child(3) {
    width: 25%;
    order: 2;
}

.requirements-cell:nth-child(4) {
    width: 25%;
    order: 6;
}

.requirements-cell:nth-child(5) {
    width: 25%;
    order: 3;
}

.requirements-cell:nth-child(6) {
    width: 25%;
    order: 7;
}

.requirements-cell:nth-child(7) {
    width: 25%;
    order: 4;
}

.requirements-cell:nth-child(8) {
    width: 25%;
    order: 8;
}

.requirements-cell img {
    height: auto;
    width: 100%;
    /* height: 100%; */
    margin: auto;
}

.requirements-cell.adults.image {
    position: relative;
}

.requirements-cell.computer img {
    width: 105%;
}


.page-home footer {
    height: 150px;
}


@media screen and (max-width: 850px)  {

    .requirements-cell.text {
        padding: 0 5vw;
        align-self: self-start !important;
        margin: 2vw 0 !important;
    }

	.requirements-cell:nth-child(1) {
		width:50%;
		order: 1;
		/*background: pink;*/
	}

	.requirements-cell:nth-child(2) {
		width:50%;
		order: 3;
		margin-bottom: 10vw;
		/*background: green;*/
	}

	.requirements-cell:nth-child(3) {
		width:50%;
		order: 5;
	}


	.requirements-cell:nth-child(4) {
		width:50%;
		order: 7;
	}

	.requirements-cell:nth-child(5) {
		width:50%;
		order: 2;
		/*background: red;*/
	}

	.requirements-cell:nth-child(6) {
		width:50%;
		order: 4;
	}

	.requirements-cell:nth-child(7) {
		width:50%;
		order: 6;
		
	}



	.requirements-cell:nth-child(8) {
		width:50%;
		order: 8;
	}

	.reasons-inner {
    flex-direction: column;
    padding: 1.5rem 3rem;
    transform: none;
    width: 100%;
    max-width: 450px;
	}

    .benefits {
        margin: 0;
        padding: 0;
    }

	.poker-hand {
		/*width: 80%;
        margin-left: -66%;
        height: 38vw;
        top: 10%;
        transform: translate(0, -50%);*/
        display:none;
    }

    section.reasons {
		padding: 0 2em;
		margin: 0;
	}

}





@media screen and (max-width: 800px)  {

    .is-guest-user.page-home .app, .centered-wrap.page-home .app {
        margin-top: 0 !important;
    }
    section.slogan {
        .title {
            .title-1 {
                font-size: 5vw;
                text-align: left;
            }
            .title-2 {
                font-size: 4vw;
                line-height: 1em;
                margin-top: 0;
                padding: 0;
                text-align: right;
            }
        }
    }


	section.central > div,
	section.central > div.login-box	{
    width: 100% !important;
    padding: 0 2em !important;
	}


	section.central > div.help.do-this	{
    height: 60vw;
	}


	h3 {
		margin-left: 0;
		width: 100%;
	}

}



@media screen and (max-width: 720px)  {
    .benefits ul li {
    align-items: start;
    flex-direction: column;
    margin-bottom: 20px;
    }
    .benefits ul li:last-child {
        margin-bottom: 0;
    }
}


@media screen and (max-width: 580px)  {


	.login {
		border-radius: 0;
	}

    .login .block {
    	padding: 1em; 
    }

	section.reasons {

		padding: 2em;
	}

	section.reasons h3 {
		margin-top: 0;
	}

    section.requirements {
    	margin: 2em 0 4em 0;
        .card-box {
            padding: 1em 2em;
            div {
                border-radius: 0;
                padding: 1em;
                font-family: 'Nunito',sans-serif;
                font-size: 1.2em;
            }
        }
    }
}

@media screen and (max-width: 480px)  {

    .logo {
        width: 80%;
    }

}

@media screen and (max-width: 420px)  {

section.central > div.help.do-this {
    height: 70vw;
    }

}


.neonbg1, .neonbg2 {
    display: none;
}
   @media screen and (min-width: 1025px) {
       section.central > div.help.do-this .lights {

        background-size: 99% 92%;
        background-position: top center;
       }

        section.central:before {
        background-image: url(/assets/img/crm/poly4.png);
        content: "";
        width: clamp(1100px,105vw,1330px);
        height: 720px;
        position: absolute;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: -90px;
        }



        .neonbg1,
        .neonbg2 {
        content: "";
        width: 50%;
        height: 25%;
        position: absolute;
        display: block;
        /* margin-top: 400px; */
        width: 100%;
        bottom: -43px;
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: clamp(170px,65%,350px) 0;
        margin-left: 0.5%;
       }


       .neonbg1 {
           background-image: url(/assets/img/crm/home-emojis-bg.png);
        }
        .neonbg2 {
                   background-image: url(/assets/img/crm/home-emojis.png);
                }

        section.central>div.help.do-this .lights {
            left: 54%;
            padding-top: 24%;
        }
        .dont-do-this {
            padding-left: 22%;
            bottom: 21%;
            font-size: 14px;
        }

        section.central/*, .neonbg1, .neonbg2*/ {
            width: 90% !important;
        }
        section.central>div {
            width: 35%;
        }
        section.central>div.help.do-this {
            width: 60%;
        }

       .funfilters
       {
           color: white;
    position: absolute;
    left: 5%;
    /* top: 50%; */
    font-size: clamp(20px, 3vw, 30px);
    line-height: clamp(20px, 3vw, 38px);
    /* transform: translate(0, -50%); */
    text-shadow: 0 0 10px #00fff6;
    color: #00fff6;
    font-family: 'FuturaMdCnBT', sans-serif;
    text-align: left;
    letter-spacing: 1.5px;
    /* padding-right: 12vw; */
    -webkit-text-fill-color: #00fff6;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-width: clamp(0.1px, 0.1vw, 1px);
    -webkit-text-stroke-color: #00fff6;
    margin-top: 0.9w;
    text-transform: uppercase;
    font-size: clamp(2em, 2.5vw, 2.2em);
    bottom: 17%;

    }
}

.central.neon-blink:before,
span.neon-blink {
    animation: neon-blink 6s infinite;
    -webkit-animation: neon-blink 6s infinite;


}


    @keyframes neon-blink {
5% {
 	opacity: 1;
 }
10% {
 	opacity: 0;
 }
    12% {
 	opacity: 1;
        }

    13% {
 	opacity: 0;
 }

15% {
 	opacity: 0.1;
 }
20% {
 	opacity: 1;
 }

 99% {
 	opacity: 1;
 }
  100% {
   opacity: 0.5;

  }
}
