.my-account {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translate(0,-50%);
    border: 3px solid #0fb6cd;
    border-radius: 100%;
    overflow: hidden;
    z-index: 2;
    opacity: 0;
    color: white;
    box-sizing: content-box;
    margin-top: 10px;
}

@media screen and (max-width: 800px)  {
    .my-account {
        top: 40px;
        right: 30px;
    }
}
/*
.my-account > div:not('.profile-pic') {
    display:none
}
*/

.my-account > div:not(.profile-pic) {
    display:none
}
.my-account .profile-pic img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    cursor: pointer;
    display:block;
}

/*
.my-account:hover {
    border-radius: 0;
    border-top: none;
    top: 0;
    width: 230px;
    height: auto;
    background: #01230cb5;
    box-shadow: 0 0 10px rgba(0,0,0,.5);
    padding: 25px;
}
*/
.my-account:hover {
   border-radius: 0;
    border-top: none;
    top: 0;
    transform: none;
    width: 230px;
    height: auto;
    background: #002d33dd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 25px;
    right: 40px;
    top: 0;
    margin-top:0;
}

.my-account:hover > div {
    display:block;
}



.my-account:hover > .profile-title {
    font-size: 25px;
}

.my-account:hover > .profile-subtitle {
    font-size: 18px;
}




.my-account:hover > .profile-pic img {
    width: 80%;
    height: auto;
    display:block;
    margin: 20px auto 10px auto;
    object-fit: cover;
    cursor: default;
    border: 1px solid black;
}


.my-account:hover > .profile-edit a {
    font-weight: bold;
    color: #0fb6cd;
    font-size: 18px;
    display: table;
    margin: auto;
}

.my-account:hover > .profile-logout {
    margin-top: 20px;
}

.my-account:hover > .profile-logout a {
    font-weight: bold;
    color: white;
    font-size: 18px;
    display: table;
    margin: auto;
    background: #ff000090;
    border: 2px solid #ff0000cc;
    text-decoration: none;
    border-radius: 5px;
    padding: 5px 10px;
}


/* ----------- */


.edit-my-profile {
   .back-button {
        font-size: 1rem;
        position: absolute;
        right: 20px;
        margin-top: 4px;
     }

    .tabs {
    display: flex;
    font-size: 0.7em;
        .tab {
            padding: 10px 0 0 0;
            opacity: 0.3;
            color: white;
            &:hover {
                opacity: 1;
            }

            &.active {

                opacity: 1;
                border-bottom: 4px solid white;
            }
        }
    }
}
