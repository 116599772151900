.white-text-shadow {
    text-shadow: 0 0 5px #ffa500, 0 0 15px #fff, 0 0 30px #fff, 0 0 40px #ffff00;
}

*.button.gradient-orange {
    border-radius: 6px;
    border: none;
    min-width: 200px;
    background: linear-gradient(to bottom, #ff9100, #ff5b00) !important;
    display: inline-grid;
    text-align: center;
    font-family: "FuturaCondensedBold", sans-serif;
    font-size: 1em;
}

*.button.gradient-gray {
    border-radius: 6px;
    border: none;
    min-width: 200px;
    background: linear-gradient(to bottom, #686868, #333333) !important;
    display: inline-grid;
    text-align: center;
    font-family: "FuturaCondensedBold", sans-serif;
    font-size: 1em;
}

*.button.gradient-blue {
    border-radius: 6px;
    border: none;
    min-width: 200px;
    background: linear-gradient(to bottom, #00aeff, #007eff) !important;
    display: inline-grid;
    text-align: center;
    font-family: "FuturaCondensedBold", sans-serif;
    font-size: 1em;
}

.is-guest-user, .centered-wrap {
    .app, .app-dashboard, .app-root {
        min-height: calc(100vh - 200px);
        margin-top: 0px;
        padding-bottom: 150px;
    }

    &.page-home .app {
        margin-top: 50px;
        padding-bottom: 0px;
    }


    footer {
    position: absolute;
    bottom:0;
    }

    
    .select {
        > div {
            border-radius: 6px;
        }
    }
    
    .select__value-container {
        > div {
            color: white;
        }
    }

    a, .login-box small {
        color: #00cbff;
        cursor: pointer;

        &.red {
            color: #ff3734;
        }
    }


    input[type="text"], input[type="button"], input[type="password"] {
        color: #ffffff;
        background: #048ca7ee;
        border-radius: 6px;

        &.white-default {
            color: #000;
            background: #fff;
            border-radius: 0px;
            &:-webkit-autofill {
                -webkit-text-fill-color: #000 !important;
                -webkit-box-shadow: 0 0 0 30px #fff inset !important;
                font-size: 0.9em;
            
            }
            &::placeholder {
                color: #00000088 !important;
            }
        }
        &:-webkit-autofill {
            -webkit-text-fill-color: #ffffff !important;
            -webkit-box-shadow: 0 0 0 30px #048ca7ee inset !important;
            font-size: 0.9em;
        }
    }
    
    ::placeholder {
        color: white;
        opacity: 1;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: white;
    }
       
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: white;
    }

    .select__indicators {
        color: black;
        background: #39bfdb;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }



    @media screen and (max-width: 800px) {
        .app, app-dashboard, app-root {
        min-height: auto;
        margin-top: 0;
        }
    }

    /*input, .dropdown > span {
        background:#999;
    }*/

    /*https://stackoverflow.com/questions/42816074/css-for-border-in-corners-only/42816202*/
    .capture-webcam {

    position: relative;
    width: 225px;
    height: 225px;
    margin: 0px;
    background: none;
    border: none;

    color: white;
    padding: 0;
    box-sizing: content-box;
    border: 3px solid #e6fb6c;
    margin: auto;
    box-shadow: 0 0 15px #e6fb6c;




        img, video, canvas, #jeeFaceFilterCanvas {
                object-fit: cover;
                width: calc(100%);
                height: calc(100%);
                
            }
    }

}


.ai-icons {
    margin-top: 20px;

    > img {
    width: 48px;
    height: 48px;
    border: 2px solid #ffffff20;
    border-radius: 50px;
    padding: 2px;
    margin: 0 3px 3px 0;
    background: #222;
    cursor: pointer;
    filter: grayscale(1);
    opacity: 0.5;
    cursor: pointer;

        &:hover, &.active {
            opacity: 1;
            filter: none;
        }

        &.active {
            border-color: gold;
        }
    }
}



.card-box {
    > div {
        // background: #05050580;
        background: #000000BD;
        border-radius: 10px;

        box-shadow: 0px 1px 12px #34999e, 0px 1px 12px #34999e;



        .card-box-header {
            /*background-image: url(/assets/img/crm/gradient-green1.png);*/
             background-image: url(/assets/img/crm/gradient-blue3.png);

            background-repeat: repeat-y;
            background-color: rgba(0,0,0,.5);

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#006977+0,000000+50,000000+100&1+0,0.5+50,0.5+100 */
            /*
background: -moz-linear-gradient(left,  rgba(0,105,119,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.5) 100%);
background: -webkit-linear-gradient(left,  rgba(0,105,119,1) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.5) 100%);
background: linear-gradient(to right,  rgba(0,105,119,1) 0%,rgba(0,0,0,0.5) 50%,rgba(0,0,0,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006977', endColorstr='#80000000',GradientType=1 );
*/

             padding: calc(var(--global-spacing)/1.5);
            font-size: 1.5em;
            line-height: 1.5em;
            font-family: "FuturaBTBoldCondensed", sans-serif;
            border-bottom: 1px solid var(--border-color);
            border-radius: 10px 10px 0 0;

            
            
        }

        .card-box-body {
            padding: calc(var(--global-spacing)/1.5);

        }

    }
 }

.skin-v2 {
    .card-box {
    > div {
        box-shadow: none;

        .card-box-header {
            background: none;
            border-bottom: 0;
            }
        }
    }
}

.is-guest-user section.slogan {
    position: relative;
    max-width: 1180px;
    padding: 0;
    transform: none;
    left: 0;
    min-height: clamp(100px,10vw,160px) !important;
}

.is-guest-user .page-register-subscription {
    padding-top: 10px;
}




.cookies {
    position: fixed;
    bottom: 0;
    padding: 20px;
    background: #0e565fdd;
    width: 100%;
    color: white;
    font-size: 1.2em;
    text-align: left;
    align-items: center;
    z-index: 9999;

    a {
    color: white;
    text-decoration: underline;
    margin: 0;
    }
}
