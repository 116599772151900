

/*

.bg-dark {
  background: #135060 !important;
}
*/

.bg-light {
  background: #158796 !important;
}
/*
.bg-deep-dark {
  background: #063c4b !important;
}
*/

.club-management-setup {
  .select {
    width: 160px;
  }

    /*
  .btn-create {
    text-align: end;
    margin-top: 30px;
  }
*/

    /*
  input[type="text"] {
    background-color: hsl(0, 0%, 100%) !important;
  }
  */

  .col-form-label{
    padding-top: 0px;
    padding-bottom: 0px;
  }

}

/*

.drag-section {
  display: inline-grid;

  .drag-container {
   // background-image: url("../assets/img/bg-drag.png");
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
   // padding: 70px;
    background-size: contain;

    max-width: 100%;
  }
}
*/

/*
.drag-container span {
    width: 100%;
    min-height: 200px;
    display: block;
    text-align: center;
    display: flex;
    justify-content: center;

    align-items: center;
}*/


/*
.drag-text {
  width: 200px;
  height: 50px;
  text-align: center;
  background-color: #09232a !important;
  vertical-align: middle;
  display: table-cell;
  color: #6efeff;
}

*/

.examinar-section {
  .box-inner {
    background-color: #135060 !important;
    border-color: #135060 !important;

    padding: 40px 0 !important;

    input {
      width: 230px;
    }
  }
}
.sharing-section {
  // border-top: 1px inset gainsboro;
  border-top: 1px solid #c8ced3;
  .box-inner {
    background-color: #135060 !important;
    border-color: #135060 !important;
    padding: 0;
    .form-group {
      .input-container {
        width: 40%;
      }
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

/* Box styles */
.policy-container {
  padding: 20px;


  h5 {
    margin-top: 0;
    text-align: center;
      font-size: 1.3em;
      margin-bottom: 1em;
  }

  .policy-terms {
    padding: 10px 0 0 0;
  }

  .myBox {
    // border: none;
    padding: 10px;
    height: 340px;
    overflow: auto;
    color: #9fe6ef;
    border: 1px solid #10606b;
    font-size: 0.8em;
      text-align: justify;
  }

  /* Scrollbar styles */
  /*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border: 1px solid #878787;
    // border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #878787;
    // border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #878787;
  }
}*/

// manager member

.club-management-member {
  /*position: absolute;*/
  .box-secondary {
    margin-top: 20px;
  }
  .box-inner {
    background-image: none !important;
    border: none !important;
    background-color: #2d6877 !important;
  }


  .action {
    .select {
      border: 2px solid #ffa500;
      cursor: pointer;
      .select__control {
        background: #ffa50066;
      }
      .select__placeholder {
        color: #fff;
      }
      .select__dropdown-indicator {
        color: #fff;
      }
      .select__input {
        color: #fff;
      }
    }
  }

  .myBox {
    // border: none;
    // padding: 10px;
    // font: 24px/36px sans-serif;
    // width: 200px;
    height: 80%;
    overflow: auto;
    background-color: #878787;
  }

  /* Scrollbar styles */
  /*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    border: 1px solid #878787;
    // border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
    // border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }*/

  .summary {
    .box-inner {
      text-align: center;
      display: grid;
      padding: 25px;
    }

    .summary-section {
      display: block;

      .summary-container {
        background-image: url("../assets/img/bg-summary.png");
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        padding: 19px 40px;
        background-size: contain;

		max-width: 100%;
		// background-color: #063c4b;
		margin: 20px 0;
      }
	}

	.summary-sharing-section {
		padding: 15px 0;
		.box-inner {
		  background-color: #135060 !important;
		//   border-color: #135060 !important;


		  padding: 0 !important;
		> div {
			text-align: start;
		}

		  input {
			width: 230px;
		  }
		}
	  }
  }
}

/* Add a black background color to the top navigation */
.topnav {
	// background-color: #333;
	// overflow: hidden;
  }

  /* Style the links inside the navigation bar */
  .club-manager{

.topnav a {
	float: left;
	display: flex;
	color: #0a2d34;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
	border-bottom: 3px solid transparent;
	.icon{
		margin: 0 10px;
  }
  height: 70px;
  width: 180px;
  align-items: center;
}

.topnav a:hover {
  border-bottom: 5px solid #ffffff;
  color: #ffffff;
}

  .topnav a.active {
    border-bottom: 5px solid #ffffff;
    color: #ffffff;
  }
}

.header-container{
	padding: 5px !important;
}

//Club setup
@media screen and (max-width: 992px) {
  .club-management-setup{
    .drag-col{
      text-align: center;
    }
    .examinar-section{
      .box-inner{
        text-align: center;
        .form-group{
          display: inline-grid;
        }
      }
    }

    //inputs
    .main{
      .col-sm-8{
        // width: auto;
      }
      .col-sm-4{
        // width: auto;
      }
    }

    .sharing-section{
      .box-inner{
        text-align: center;
        .form-group{
          margin: 0;
          display: inline-grid;
          .input-container{
            width: 100%;
          }
        }
      }
    }



  }
}

// Member setup

@media screen and (max-width: 992px) {
  .club-management-member{
    // .filter-by{
    //   display: inline-block;
    // }
    // .order-by{
    //   display: inline-block;
    // }
    .summary-container{
      text-align: center;
    }
        .summary-sharing-section{
          .box-inner{
            justify-content: center;
          }
        }
  }

}

@media screen and (min-width: 992px) and (max-width: 1120px) {

  .club-management-member{
    .summary{
      .summary-container{
        p{
          margin-bottom: 0rem !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1121px) and (max-width: 1200px) {

  .club-management-member{
    .summary{
      .summary-container{
        p{
          margin-bottom: 0.5rem !important;
        }
      }
    }
  }
}
}
