.modal_form {

    overflow-x:hidden;

	> .modal_header {
	    background: var(--header-background);
        position: absolute;
        width: 100%;
        z-index: 9999;

	    > .row {
	        margin: 0;

	        > .col {
	            padding: calc(var(--global-spacing)/1.5) calc(var(--global-spacing) * 2);

                &.tabs {

                    padding: 0;
                    height: auto !important;


                    .tabs-inner {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        max-width: 35vw;
                        margin: auto;

                        .tab {

                            /*background: pink;*/
                            width: 100%;
                            height: 100%;
                            align-items: center;
                           /* align-content: space-around;*/
                            display:flex;
                            justify-content: center;
                            /*flex-direction: column;*/
                            position: relative;
                            font-size: 1.20em;
                            color: cyan;
                            max-width: 250px;
                            white-space: nowrap;



                            .icon {
                                background-repeat: no-repeat;
                                &.cog {
                                    background-image: url('/assets/img/crm/icon-cog.png');
                                    filter: invert(37%) sepia(15%) saturate(5012%) hue-rotate(145deg) brightness(98%) contrast(101%);
                                }
                                 &.movie {
                                    background-image: url('/assets/img/crm/icon-movie.png');
                                    width: 29px;
                                    height: 35px;
                                }
                                &.pause {
                                    background-image: url('/assets/img/crm/icon-pause.png');
                                }
                                &.users {
                                    background-image: url('/assets/img/crm/icon-users-blue.png');
                                }

                                
                                opacity: 1;


                            }


                             &:hover, &.active {
                                color: white;
                                text-decoration: none;
                                background: none;

                                &:after {
                                    content: "";height: 5px;width: 100%;color: white;display: block;position: absolute;bottom: 0;left: 0;background: white;
                                }

                              .icon {
                                filter: none;
                                opacity: 1;
                                }
                            }
                        }
                    }
                }
	        }
	    }


        .title span {
            font-size: 1.75em;
            line-height: 1.5em;
            font-family: 'Roboto Condensed', sans-serif;
        }
	}

	> .modal_body {
		background: var(--body-background);
		padding: calc(var(--global-spacing) * 0.5) calc(var(--global-spacing) * 1.5);
        line-height: 1em;

        .row {
            &:not(.top) {
                align-items: center;
            }

            &:not(:last-child) {
              margin-bottom: calc(var(--global-spacing)/2);
            }

        }

        >.row {
            //margin-bottom: 73px;
        }


		&.black-fix {
			padding-right: calc(var(--global-spacing) * 2.5);
		}


        .modal_action_buttons {
            align-self: flex-end;
            text-align: right;
            padding: 0;
        }

        .box {
            margin-bottom: var(--global-spacing);
            width: 100%;
            height: 65px;

            > .box-inner {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;

                > div.icon  {
                    left: 5px;

                    + div {
                    padding-left: 50px;
                    margin-right: 10px;
                    }
                }

            }

            &.summary {
                height: 65px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

	}


    .close {
        opacity: 1;
        margin-right: 10px;

        > a {
            align-items: center;
            /*background: red;*/
            color: white !important;
            text-decoration: none;
            border-radius: 1em;
            overflow: hidden;
            display: block;
            text-align: center;
            /*line-height: 32px;*/
            padding: 0;
            width: 36px;
            height: 36px;
            font-size: 1.2em;
            position: absolute;
            right: 15px;
            top: 50%;
            font-weight: normal;
            transform: translateY(-50%);
            text-shadow: none;
            opacity: 1 important;
            border: 3px solid;
            font-weight: bold;

            &:before {
            content: "×"; //&times;
            width: 100%;
            display: block;
            height: 100%;
            /*margin: -1px 0 0 0px;*/
            margin: 2px 0 0 0px; /* Roboto Condensed */
            }
        }


    }


    label.col-form-label {
    line-height: 1em;
    }
}



.black-50 {
	background: rgba(0,0,0,.5);
    box-shadow: 1.5rem 0 0 rgba(0,0,0,.5);
	padding: 1.5rem 0 1.5rem 1.5rem !important

}

.white-15 {
	background: rgba(255,255,255,.15);
   /* box-shadow: 1.5rem 0 0 rgba(0,0,0,.5);
	padding: 1.5rem 0 1.5rem 1.5rem !important*/

}



body.modal_opened {

    &:after {
        content: "";
        background: rgba(0,0,0,.7);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9998;

    }

    .modal_form.show_modal {
        position: fixed;
        z-index: 9999;
        width: calc(100% - 40px) !important;
        top: 0;
        width: 100%;
        height: auto;
        overflow: auto;
        top: 50%;
        /*transform: translate(0, -50%);*/
        transform: translate(-50%, -50%);
        overflow: hidden;
        /*max-width: 1600px;*/
        left: 50%;
        max-height: calc(100% - 40px);
        overflow: hidden;
        border-radius: 10px;

        &.subscription_modal {
            width: 500px !important;
            
            * > a.button {
                width: 200px;
            }
        }
        &.waiting_popup {
            width: 500px !important;   
        }



        .modal_body {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 90vh;
            padding-top: 73px;
        }

        .table-container
        {
             height: calc(100% - 85px);


        > div  {
        overflow-x: auto;
            height: 100%;
            }
        }

        table {
            /*width: calc(100% + (var(--global-spacing)*2));
            margin: calc(-1 * var(--global-spacing));*/
            width: 100%;
            border-collapse: collapse;
            min-width: 800px;

            th, td {
                padding: calc(var(--global-spacing)/2) 5px;
                border-bottom: 1px solid;
                text-align:left;
                &.center {
                    text-align: center;
                }
                &.right {
                    text-align: right;
                }
            }
            th {
                border-bottom-color: var(--border-color-th);
            }
            td {
                border-bottom-color: var(--border-color-td);
            }
            th:first-child, td:first-child  {
                padding-left: var(--global-spacing);
            }
            th:last-child, td:last-child  {
                padding-right: calc(var(--global-spacing)/2);
            }
            tr:last-child td {
                border:none;
            }

        }


    }






}

body:not(.modal_opened) .modal_form {
    margin-bottom: var(--global-spacing);
}


