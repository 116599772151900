/* $global_padding: 20px; */


:root {
  --global-spacing: 20px;
}


.box {
	--border-color: #707070;
	--border-color-th: #70707050;
	--border-color-td: #70707030;
	--background-color-primary: #6e6e6e;
	--background-color-secondary: #3d3d3d;
	
	
	&.green {
		--border-color: #1dd600;
		--border-color-th: #1dd60050;
		--border-color-td: #1dd60030;
		--background-color-primary: #2ace11;
		--background-color-secondary: #1d6b11;
		
	}
	
	&.blue {
		--border-color: #01bad4;
		--border-color-th: #01bad450;
		--border-color-td: #01bad430;
		--background-color-primary: #0fb6cd;
		--background-color-secondary: #10606b;

	}

}
/*
.bread-crum {
    a {
        --breadcrum-color: #127d8a;
    }
}*/
.modal_form {
    th,td {
        --border-color-th: #ffffff30;
		--border-color-td: #ffffff30;
    }
}

.card-box-header {
   --border-color: #2ace1120;
}

.is-guest-user .card-box-header,
.edit-my-profile .card-box-header{
   --border-color: #00697770;
}






.text {
	--text-color: white;
	
	&.green {
		--text-color: #12ff00;
	}
	
	&.orange {
		--text-color: #ff7600;
	}	
	&.blue {
		--text-color: #10ffff;
	}
	&.link-blue {
		--text-color: #00cbff;
	}
	
	&.red {
		--text-color: #ff3734;
	}

    &.yellow {

        --text-color: #d9af01;
	}

	&.light-yellow {
		--text-color: #feff00;
	}
    &.black {
		--text-color: #000000;
	}

    &.gray {
		--text-color: #777;
	}

    &.dark-gray {
		--text-color: #333;
	}

	&.bg-dark-blue {
		--background-color: #001c20;
	}


}



.button {

	--button-background: #3b3b3b;
	--button-border: #707070;
	--button-color: white;
	

	&.green {
		--button-background: #006241;;
		--button-border: #1cfff4;
		&:hover {
			--button-background: #1cfff4;
		}
	}
	
	&.orange {
		--button-background: #f67700;
		--button-border: #ffca0e;
		&:hover {
			--button-background: #ffca0e;
		}
	}
	
	&.blue {
		--button-background: #004862;
		--button-border: #1a82ff;
		&:hover {
			--button-background: #1a82ff;
		}
	}
	
	&.red {
		--button-background: #620021;
		--button-border: #ff1a26;
		&:hover {
			--button-background: #ff1a26;
		}
	}

    &:hover {
        &.hover-orange {
            --button-background: #f67700;
		    --button-border: #ffca0e;
        }
    }
}


.skin-v2 .button {

	&.green {
		--button-background: #27631c60;
		--button-border: #3cff1a60;
	}

	&.orange {
		--button-background: #f6770060;
		--button-border: #ffca0e60;
		&:hover {
			--button-background: #ffca0e60;
		}
	}

}

.cookies .button {

	&.green {
		--button-background: #27631cdd;
		--button-border: #3cff1add;
		&.hover {
			--button-background: #3cff1add;
		}
	}

	&.orange {
		--button-background: #f67700dd;
		--button-border: #ffca0edd;
		&.hover {
			--button-background: #ffca0edd;
		}
	}

}

.modal_form {
	
	--header-background: #555;
	--body-background: #222;
	
	&.green {
		--header-background: #11580a;
		--body-background: #102d0f;
	}

    &.blue {
		/*--header-background: #057384;*/
        --header-background: #163d42;
		/*--body-background: #135060;*/
        --body-background: #11262be6; /* f2 = 95%, e6 = 90% https://css-tricks.com/8-digit-hex-codes/ */
	}
}


.animated-border {
    --animated-border: #333;
    --animated-border-bg: transparent; /*#101010*/
    --animated-border-textcolor: #fff;

    &.orange {
        --animated-border: #f6770090;
        --animated-border-bg: transparent; /*#101010*/
        --animated-border-textcolor: #f67700;
	}
	&.yellow {
        --animated-border: #e5fb6c90;
        --animated-border-bg: transparent; /*#101010*/
        --animated-border-textcolor: #e5fb6c;
    }
    &.green {
        --animated-border: #11580a;
        --animated-border-bg: transparent; /*#101010*/
        --animated-border-textcolor: #11580a;
    }
    &.red {
        --animated-border: #620021;
        --animated-border-bg: transparent; /*#101010*/
        --animated-border-textcolor: #620021;
    }   
    &.blue {
        --animated-border: #51869890;
        --animated-border-bg: transparent; /*#101010*/
        --animated-border-textcolor: #518698;
    }
}

.capture-webcam, .drag-section {
    --border-width: 3px;
}


/* ------------------ */



