* {
	-webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
	margin: 0;
	padding: 0;
}

*:not(input):not(textarea) {
 -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

*, *::before, *::after {
    box-sizing: border-box;
}


html {
    position: relative;
    min-height: 100%;
}
body {
    margin: 0 0 0px;
    padding: 15px;
	overflow-x: hidden;
    font-family: 'Roboto Condensed', 'Roboto', 'Arial Narrow', sans-serif;
}


.cursor-pointer {
    cursor: pointer;
}

.force-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

a {
	color:  #00af00;
    cursor: pointer;
}


footer a {
	color: #999;
}

h3 {
    font-size: 28px;
    font-weight: normal;
    /* margin-top: -10px; */
    /* text-align: center; */
    margin-bottom: 2vw;
    transition: opacity 0.5s;
    width: calc(100% + 4vw);
    border-bottom: 1px solid green;
    margin-left: -2vw;
    padding-bottom: 2vw;
}

.form-control {
    border-radius: 0;
}

input[type="text"], input[type="text"]:placeholder-shown, input[type="button"], input[type="password"], input[type="submit"], textarea {

    padding: 0.3em 1em;
    border: 0px;
    color: #000000;
    font-size: 0.9em;

    &.bg-deep-dark {
        color: white !important;
        &::placeholder {
        color: rgba(255,255,255,.5) !important;
        }
    }

    /*&:placeholder.bg-deep-dark {
        color: rgba(255,255,255,.5) !important;
    }*/
}

/* If input is not empty */
input:not(:placeholder-shown) {
  /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
  opacity: 1;
}

/* If input is empty */
input:placeholder-shown {
  opacity: 0.5;
  &:focus {
      opacity: 1;
  }
}

input[type="text"], input[type="button"], input[type="password"], input[type="submit"] {
    height: 38px;
}

 input[type="button"], input[type="submit"] {
	 color: #fff; 
 }


input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
        min-height: 38px;
        padding: 0.3em 1em;
        font-size: 0.9em;
        border: 0;
}

::-webkit-input-placeholder { /* Edge */
  color: #222;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #222;
}

::placeholder {
  color: #222;
}

input.green {
    background: #006241;
    border: 2px solid #1cfff4; 
    cursor: pointer;
}

input.orange {
    border: 2px solid #ffa500;
    background: #ffa50066;
	cursor: pointer;
}

.skin-v2 input.green {
    border: 2px solid #3cff1a60;
}


.show {
	opacity: 1 !important;
}

.show.transparent {
	opacity: 0.2 !important;
}

.blink {
  animation-name: pulse; 
  animation-duration: 0.7s; 
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse; 
  -webkit-animation-duration: 0.7s; 
  -webkit-animation-iteration-count: infinite; 
  
}

@keyframes pulse {
  0% {
    transform: scale(1.0);   
    opacity: 0.5;
  }
  50% {
   transform: scale(1.2);  
    opacity: 1;
  }
  100% { 
    transform: scale(1.0);  
    opacity: 0.5;
  }
}

/* ------------------------------*/
/* https://www.digitalocean.com/community/tutorials/css-scrollbars */
/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  /*scrollbar-color: blue orange;*/
    scrollbar-color: #10606b transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
*::-webkit-scrollbar-track {
  /*background: orange;*/
    background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #10606b;
  border-radius: 20px;
  /*border: 3px solid orange;*/
  border: none;
}


body.modal_opened {
    scrollbar-color: transparent transparent;
}

body.modal_opened::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/*
// ----
body.modal_opened {
    scrollbar-color: transparent transparent;
}
body.modal_opened::-webkit-scrollbar-thumb {
    background-color: transparent;
}
// ---

body.page-clubs,
body.page-clubs * {
    scrollbar-color: #0fb6cd transparent;
}

body.page-clubs::-webkit-scrollbar-thumb,
body.page-clubs *::-webkit-scrollbar-thumb {
     background-color: #0fb6cd;
}

.modal_form {
    &.green {

        * {
            scrollbar-color: var(--header-background) transparent;
        }

        *::-webkit-scrollbar-thumb {
             background-color: var(--header-background);
        }
    }
}
*/

/* ------------------------------*/

.container-fluid {
	padding: 0px;
}
.app-header {
	border: none;
    position: relative !important;
    min-height: clamp(100px,10vw,160px) !important;
}
/* ------------------------------*/
body {
	background: #050e04;
	/*font-family: 'Roboto Condensed', sans-serif;*/
	
}
/*
body:before {

    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background: #0fb6cd;
    position: absolute;
    -webkit-filter: blur(50px);
    filter: blur(75px);
    top: 0px;
   
    transform: scaleY(0.3) translateX(-50%);
    left: 50%;
    max-width: 600px;
	}
*/
iframe {
	border: 0;
	overflow: hidden;
}

.wrapper, .wrapper .inner {
	width: 100vw;
	min-height: 100vh;
	position: absolute;	
	left: 0;
	top: 0;

	}

/*
body , .wrapper, .wrapper .inner {
    background: red !important;
}
*/
.wrapper {
	background-repeat: no-repeat;
	background-position: top left;
	background-size: cover;
}


.logo {
	width: 15vw;
	height: 100%;
	background-image: url('/assets/img/crm/logo.png');
	background-size: auto;
	background-position: left;
	background-repeat: no-repeat;
	padding: 0;
	background-size: contain;
	cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}


.edition {
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 0.1vw;
	word-spacing: 0.1vw;
	display: none;
	color: rgba(255,255,255,.5);
	right: 15px;
	left: auto;
	top: 65px;
	background-position: top center;
	position: absolute;
	width: calc(20vw - 15px);
}

.edition span {
	 background: black;
	 padding: 10px;
	 border-radius: 5px;
	 box-shadow: 0px 2px 2px rgba(255,255,255,0.2) inset;
	 margin:auto;
	 display:table;
}

body.start .edition {
	 display: block;
	 text-align: center;
}


.inner {
	display:flex;
	flex-direction: column;
}

section.main, section.slogan, section.navigation {
	max-width: 1600px;
	margin: auto;
	width: calc(100% - var(--global-spacing)*2) !important;
    padding: var(--global-spacing) 0 ;
}

header {
	/*height: 250px;*/
	width: 100%;
	position: relative !important;
	height: auto !important;
	background: transparent !important;
	
	.navigation {
		padding: 0 0 calc(var(--global-spacing)*2) 0 ;
	
		nav {
			/*display:flex;*/
			/*max-width: 1448px;*/
			width: 100% !important;
            height: 4vw;
            max-height: 80px;
            min-height: 50px;
			padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
			
			a {
				/*padding:  var(--global-spacing) 0;*/
                height: 100%;
				margin: 0;
				text-align: center;
				text-decoration: none;
				width: 20%;
                max-width: 350px;
				background-repeat: no-repeat;
				background-size: auto 100%;
				background-position: center;
				color: black;
				font-size: 1.2em;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				
				> :nth-of-type(1) {
				    /* margin-right: calc(var(--global-spacing)/2);*/
                   margin-right: 25px;
				}
				
				&.active, &:hover {
					color: white;
				}
				
				&.red {
				background-image: url('/assets/img/crm/nav/red-inactive.png');
					&.active, &:hover {
						background-image: url('/assets/img/crm/nav/red-active.png');
					}
				}
				
				&.blue {
				background-image: url('/assets/img/crm/nav/blue-inactive.png');
					&.active, &:hover {
						background-image: url('/assets/img/crm/nav/blue-active.png');
					}
				}
				
				&.orange {
				background-image: url('/assets/img/crm/nav/orange-inactive.png');
					&.active, &:hover {
						background-image: url('/assets/img/crm/nav/orange-active.png');
					}
				}
				
				&.green {
				background-image: url('/assets/img/crm/nav/green-inactive.png');
					&.active, &:hover {
						background-image: url('/assets/img/crm/nav/green-active.png');
					}
				}
				
			}

		}
	}
}




section {
	min-height: calc(100vh - 150px);
	width: 100vw;
	padding: 0;
}



footer, .app-footer {
    color: white;
    font-size: clamp(12px, 1.6vw, 16px);
    height: 100px;
    text-align: center;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: none !important;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    left: 0;
    font-family: 'FuturaMdCnBT',sans-serif;
    font-weight: 500;
    background: linear-gradient(to bottom, transparent, black, black);
    z-index: 1000;
    letter-spacing: 0.5px;
    flex: auto;
}

//footer {font-size: 0.9em;line-height: 3em;height: 4em;padding: 0 1em;width: calc(100% - 2em);}
footer a { color: #f5b100; text-decoration: none; margin: 0 0.5em }
footer a:hover { text-decoration: underline }


/*-------------*/



section.slogan {
  max-width: 1600px;
    margin-bottom: 2vw;
    position: absolute;
    left: 50%;
    padding: 0 11vw 0 0;
    transform: translate(-50%,0);
    top: 10px;
}

@media screen and (max-width: 800px) {
    section.slogan {
        position: relative;
        left: 0;
        padding: 0 ;
        transform: none;

    }

    .app-header {
        min-height: auto !important;
    }

}



/* neon header*/

@-webkit-keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500,
      0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff6a9;
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
    color: #111;
    text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
    text-shadow: 0 0 5px #ffa500, 0 0 15px #ffa500, 0 0 20px #ffa500,
      0 0 40px #ffa500, 0 0 60px #ff0000, 0 0 10px #ff8d00, 0 0 98px #ff0000;
    color: #fff6a9;
  }
}


/* Subscription */
section.subscription {
    height: 360px;
    min-height: 360px;
    width: 100%;
    text-align: center;
    min-width: 130px;

    &.beginner {
        background: linear-gradient(0deg, #2f700080, transparent);
    }
    &.amateur {
        background: linear-gradient(0deg, #46071a80, transparent);
    }
    &.pro {
        background: linear-gradient(0deg, #06254580, transparent);
    }

    *.button.gradient-orange {
        min-width: 10px !important;
    }

    * > ul {
        margin-left: 1.8rem !important;
        margin-right: 0.5rem !important;
        list-style-type: none;
        font-family: "FuturaPTCondBook", sans-serif;
    }

    * > ul > li:before {
        content: "–"; /* en dash */
        position: absolute;
        margin-left: -1.1em; 
    }

    * > div {
        &.bottom {
            bottom: 10px;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        &.top {
            height: 80px;
            margin: auto;
            width: 90%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding-top: 14px;
            &.beginner {
                background-image: url('/assets/img/crm/title-bg-trial.png');
            }
            &.amateur {
                background-image: url('/assets/img/crm/title-bg-amateur.png');
            }
            &.pro {
                background-image: url('/assets/img/crm/title-bg-pro.png');
            }
            
        }
    }

    * > span {
        &.free {
            font-size: 1.3em;
        }
        &.disabled {
            text-decoration: line-through;
            color: #ff000090;
        }
        &.shadow-black {
            text-shadow: 2px 2px #00000060;
        }
    }

    * > a {
        &.shadow-black {
            box-shadow: 2px 2px #00000060;
        }
    }
}
/*-------------*/
/* light effects in boxes */

.gold-light {
    border: 5px solid #e9ff72;
    box-shadow: 0 0 15px #fff000, inset 0 0 15px #fff000 !important;
}

/*-------------*/

.box-blue-shadow {
    box-shadow: 0px 0 0px 1px #a0ec9b, 0 0 20px 7px #34999e;
}


/*.app-footer {
    background-color: transparent;
   background: linear-gradient(to bottom, transparent, black, black);
    footer {
        font-family: "FuturaMdCnBT", sans-serif;
        background-color: transparent !important;
        letter-spacing: 0.5px;
    }
}*/

.box-blue-without-top-shadow {
    box-shadow: 0px 0 0px 1px #a0ec9b, 0 0 20px 7px #34999e;
}

.blue-border {
    border-width: 1px;
    border-style: solid;
    border-color: #01bad4;
}

.black-background {
    background: #000000BD;
}
.box-blue-without-bottom-shadow {
    box-shadow: 0px 0 0px 1px #a0ec9b, 0 0 20px 7px #34999e;
}

.box-blue-without-top-bottom-shadow {
    box-shadow: 1px 0 12px -1px #34999e, -1px 0 12px -1px #34999e;
}

.box {
	
	width: 100vw;
	margin-bottom: var(--global-spacing);


	> div {
		padding: 0 0 0 0px;
		margin: auto;
		
		.box-inner {
			padding: var(--global-spacing);
			/*background: black;*/
			position: relative;
			border-width: 1px ;
			border-style: solid;
			border-color: var(--border-color);
			border-top-width: 0;
	
			background-repeat: repeat-y;
			background-position: top left;
			background-color: rgba(0,0,0,0.5);
		
			> div {
			margin: 0 calc(var(--global-spacing)/2);
			
				&.icon + div {
				  margin-left: calc(var(--global-spacing)*0.7);
				}
			
			}
		
		}
		
		&:first-child {
			.box-inner {
				border-top-width: 1px;
			}
		}
		
		
		&.box-rounded-top {
			border-top-left-radius: 15px; 
			border-top-right-radius: 15px; 
			
			.box-inner {
					border-top-left-radius: 15px; 
					border-top-right-radius: 15px; 
			}
		}
		
		&.box-rounded-bottom {
			border-bottom-left-radius: 15px; 
			border-bottom-right-radius: 15px; 
			.box-inner {
                border-bottom-left-radius: 15px; 
                border-bottom-right-radius: 15px; 
			}
            .box-inner-without-radius {
                border-bottom-left-radius: 0 !important; 
                border-bottom-right-radius: 0 !important; 
            }

            
		}
	
	}

	.box-primary {
	
		background: var(--background-color-primary);
		
		.box-inner {
			padding: calc(var(--global-spacing)/2) var(--global-spacing) calc(var(--global-spacing)/2) calc(32px + var(--global-spacing)*2);
			min-height: 70px;
			align-items: center; 
			display: flex;
			flex-wrap: wrap;
		}
		

	}
	
    .box-inner {


    }

	.tabs {
		padding: 0 !important;
		height: calc(30px + (var(--global-spacing)*2)) !important;
		/*background: green;*/
		
		.tabs-inner {
			
			padding: 0;
			display: flex;
			/*background: pink;*/
			height: 100%;
			margin-left: auto;
			margin-right: auto;
			
			
            .tab {
                padding: 0 calc(var(--global-spacing)*2) 0 calc(var(--global-spacing)*2);
                color: cyan;//var(--background-color-secondary);
                text-decoration: none;
                height: 100%;
                display: flex;
                align-items: center;
                position: relative;

                &.active, &:hover {
                    background: var(--background-color-secondary);
                    color: white;
                }


            }
		}
		
	}
	
	&.green {
		.box-primary {
			.box-inner {
				background-image: url('/assets/img/crm/gradient-green1.png');
			}
		}
		
		.box-secondary {
			.box-inner {
				background-image: url('/assets/img/crm/gradient-green2.png');
			}
		}
	}
	
	&.blue {
		.box-primary {
             /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#0fb6cd+0,0fb6cd+15,000000+100&1+0,1+15,0+16,0+16,0+100 */
            background: -moz-linear-gradient(left,  rgba(15,182,205,1) 0%, rgba(15,182,205,1) 30px, rgba(15,180,203,0.5) 150px, rgba(0,0,0,0.5) 100%);
            background: -webkit-linear-gradient(left,  rgba(15,182,205,1) 0%,rgba(15,182,205,1) 30px,rgba(15,180,203,0.5) 150px,rgba(0,0,0,0.5) 100%);
            background: linear-gradient(to right,  rgba(15,182,205,1) 0%,rgba(15,182,205,1) 30px,rgba(15,180,203,0.5) 150px,rgba(0,0,0,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0fb6cd', endColorstr='#00000000',GradientType=1 );

			.box-inner {
				background-image: url('/assets/img/crm/gradient-blue1.png');
               /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#006977+0,006977+9,006977+9,000000+40,000000+100&1+0,1+10,0.5+40,0.5+100 */
/* background: -moz-linear-gradient(left,  rgba(0,105,119,1) 0%, rgba(0,105,119,1) 9%, rgba(0,102,115,1) 10%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0.5) 100%);
background: -webkit-linear-gradient(left,  rgba(0,105,119,1) 0%,rgba(0,105,119,1) 9%,rgba(0,102,115,1) 10%,rgba(0,0,0,0.5) 40%,rgba(0,0,0,0.5) 100%);
background: linear-gradient(to right,  rgba(0,105,119,1) 0%,rgba(0,105,119,1) 9%,rgba(0,102,115,1) 10%,rgba(0,0,0,0.5) 40%,rgba(0,0,0,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006977', endColorstr='#80000000',GradientType=1 ); */


			}
		}
		
		.box-secondary {
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#10606b+0,10606b+15,000000+100&1+0,1+15,0+16,0+16,0+100 */
            background: -moz-linear-gradient(left,  rgba(16,96,107,1) 0%, rgba(16,96,107,1) 30px, rgba(0,0,0,0.5) 150px, rgba(0,0,0,0.5) 100%);
            background: -webkit-linear-gradient(left,  rgba(16,96,107,1) 0%,rgba(16,96,107,1) 30px,rgba(0,0,0,0.5) 150px,rgba(0,0,0,0.5) 100%);
            background: linear-gradient(to right,  rgba(16,96,107,1) 0%,rgba(16,96,107,1) 30px,rgba(0,0,0,0.5) 150px,rgba(0,0,0,0.5) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10606b', endColorstr='#00000000',GradientType=1 );


			.box-inner {
				background-image: url('/assets/img/crm/gradient-blue2.png');
                /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00333a+0,00333a+10,000000+40,000000+100&1+0,1+10,0.5+40,0.5+100 */
                /*
background: -moz-linear-gradient(left,  rgba(0,51,58,1) 0%, rgba(0,51,58,1) 10%, rgba(0,0,0,0.5) 40%, rgba(0,0,0,0.5) 100%);
background: -webkit-linear-gradient(left,  rgba(0,51,58,1) 0%,rgba(0,51,58,1) 10%,rgba(0,0,0,0.5) 40%,rgba(0,0,0,0.5) 100%);
background: linear-gradient(to right,  rgba(0,51,58,1) 0%,rgba(0,51,58,1) 10%,rgba(0,0,0,0.5) 40%,rgba(0,0,0,0.5) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00333a', endColorstr='#80000000',GradientType=1 );
*/
                
			}
            .box-final {
                height: 80px;
                background: rgba(16,96,107,0.5);
                position: relative;
                align-items: center;
                justify-content: center;
                display: flex;
                color: cyan;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                .dropdown {
                    width: 22px;
                    height: 22px;
                    background-image: url('/assets/img/crm/sort-down-cyan.png');
                    margin-right: 10px;
                }
                .content-center {
                    align-items: center;
                    display: flex;
                }
            }
		}
	}
	
	

	
	.box-secondary {
		background: var(--background-color-secondary);
		/*border-radius: 0 0 15px 15px;*/
		padding-top: 0;
	
		.box-inner {
		border-color: var(--border-color);
			
		display: flex;
		overflow-y: hidden;
		overflow-x: auto;
		/*-webkit-overflow-scrolling:auto;

			&::-webkit-scrollbar {
			  -webkit-appearance: none;
			  width: 7px;
			  height: 7px;
			}

			&::-webkit-scrollbar-thumb {
			  border-radius: 4px;
			  background-color: var(--background-color-primary);
			  //box-shadow: 0 0 0px 1px var(--border-color);
			  margin: 0 10px;
			}
        */
				
		}

			+ .box-primary > div {
			border-top-width: 1px;
			margin-top: -1px; 
		}
		
	}
	

	table {
		width: calc(100% + (var(--global-spacing)*2));
		margin: calc(-1 * var(--global-spacing));
		border-collapse: collapse;
		min-width: 800px;
	
		th, td {
			padding: var(--global-spacing) 5px;
			border-bottom: 1px solid;
			text-align:left;
			&.center {
				text-align: center;
			}
			&.right {
				text-align: right;
			}
		}
		th {
			border-bottom-color: var(--border-color-th);
		}
		td {
			border-bottom-color: var(--border-color-td);
		}
		th:first-child, td:first-child  {
			padding-left: var(--global-spacing);
		}
		th:last-child, td:last-child  {
			padding-right: var(--global-spacing);
		}
		tr:last-child td {
			border:none;
		}
	
	}
	
	td.club-logo {
		
		width: 100px;
		
		img {
			width: calc(100% - 10px);
			margin: -10px auto;
			height: auto;
			min-width: 50px;
		}
	}
	
	
	input[type="text"], 
	input[type="button"], 
	input[type="password"], 
	input[type="submit"] {
	/*padding: 0 5px;*/
    margin: 0px;
    border: 0px;
    color: #000000;
    display:inline-block;

	}

	input[type="text"], 
	input[type="button"], 
	input[type="password"] {
    background: white;
    color: #333;

	}

	
} /* .box */

@media screen and (min-width: 1680px) {

	.box {
		margin-bottom: calc(var(--global-spacing) * 1.3);
    }
}

/* --------------------------------- */

.text {
	color: var(--text-color);
}

.button {
	display:inline-flex;
    /*margin: calc(var(--global-spacing) / 2);*/
	margin: 0;
    padding: 0 var(--global-spacing);
	overflow: hidden;
	text-decoration:none !important;
	color: var(--button-color) !important;
	background: var(--button-background) !important; 
	border: 2px solid var(--button-border);
	font-size: 0.8em;
	height: 38px;
    align-items: center;
    &:hover {
       color: var(--button-color) !important;
	   background: var(--button-background) !important;
    }
}

.button.animated {
    border:0;
    position: relative;
    width: 100%;
    background: none !important;

    & .animated-border {
        width: calc(100% - 1px);
        height: 38px;
        line-height: 38px;
        padding: 0;
    }
}

td > .button {
	margin: calc(var(--global-spacing) / 4);
}

.msg {
	background: var(--background-color);
	color: var(--text-color);
	padding:  calc(var(--global-spacing) / 3) calc(var(--global-spacing) / 2);
	font-size: 0.9em;
	
    &.small {
        font-size: 0.8em;
    }
}

/* --------------------------------- */
textarea.introduction-text {
    height: 200px;
}
/* --------------------------------- */

.icon {
    width: 32px;
    height: 32px;
	background-size: cover;
	display: inline-block;
}

nav a {
	.icon {
        position: absolute;
        margin-left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;


		&.filter {
			background-image: url('/assets/img/crm/icon-filter.png');
             width: 3vw;
            max-width: 25px;
            min-width: 15px;
		}
		&.user {
			background-image: url('/assets/img/crm/icon-user.png');
            width: 2.5vw;
            max-width: 28px;
            min-width: 22px;
		}
		&.users {
			background-image: url('/assets/img/crm/icon-users.png');
			/*width: 45px;*/
            width: 3vw;
            max-width: 35px;
            min-width: 25px;
		}
		&.chips {
			background-image: url('/assets/img/crm/icon-chips.png');
			width: 2.7vw;
            height: 2.7vw;
            max-width: 35px;
            min-width: 20px;
		}

        filter: invert(1);
        transform: translateY(-50%);
        top: 50%;
	}
	
	&.active, &:hover {
		.icon {
			filter: invert(0);
		}
	}

    &.nav-dashboard > span {
        margin-left: -5px;
    }

    &.nav-clubs > apan {
        margin-left: -10px;
    }
}

.box-inner > .icon {
	
	position: absolute;
    transform: translate(0,-50%);
    top: 50%;
    left: var(--global-spacing);
    background-repeat: no-repeat;

	&.filter {
		background-image: url('/assets/img/crm/icon-filter.png');
		margin-left: 5px;
	}
	
	&.chips {
		background-image: url('/assets/img/crm/icon-chips.png');
		width: 40px;
		height: 40px;
		margin-left: 1px;
	}
	
	&.users {
		background-image: url('/assets/img/crm/icon-users.png');
		margin-left: 0px;
		width: 45px;
	}

}




.tabs {
	
	.tabs-inner {
	
		.tab {
		
			.icon {
		
				margin-right: var(--global-spacing);

				&.home {
					background-image: url('/assets/img/crm/icon-home-black.png');
					margin-top: -6px
				}
				
				&.chips {
					background-image: url('/assets/img/crm/icon-chips-black.png');
					width: 38px;
					height: 38px;
					margin-left: -5px;
				}
				
				&.users {
					background-image: url('/assets/img/crm/icon-users-black.png');
					margin-left: -7px;
					width: 39px;
					height: 30px;
				}
				
				&.trophy {
					background-image: url('/assets/img/crm/icon-trophy-black.png');
					margin-left: -7px;
					width: 34px;
				}

			}

			&:hover,&.active {
			
				.icon {

					&.home {
						background-image: url('/assets/img/crm/icon-home.png') !important;
					}
					
					&.chips {
						background-image: url('/assets/img/crm/icon-chips.png') !important;
					}
					
					&.users {
						background-image: url('/assets/img/crm/icon-users.png') !important;
					}
					
					&.trophy {
						background-image: url('/assets/img/crm/icon-trophy.png') !important;
					}

				}
			
			} 
		
		}
	
	}
	
}
	
	
	
.box.blue {
	
	.tabs {
		
		.tabs-inner {
		
			.tab {
			
				.icon {

					&.home {
						background-image: url('/assets/img/crm/icon-home-blue.png');
					}
					
					&.chips {
						background-image: url('/assets/img/crm/icon-chips-blue.png');
					}
					
					&.users {
						background-image: url('/assets/img/crm/icon-users-blue.png');
					}
					
					&.trophy {
						background-image: url('/assets/img/crm/icon-trophy-blue.png');
					}

				}
		
			}
		
		}
		
	}
	
}


.clubs-list {
    .icon.cog {
        background: url('/assets/img/crm/icon-golden-cog.png');
        width: 20px;
        height: 20px;
    margin: 4px 0 0 -5px;
        background-size: cover;
	   position: absolute;
    }
}

@media screen and (max-width: 1024px) {
 
	.tabs {
		.tabs-inner {
			.tab {
				.icon {
					display: none;
				}
			}
        }
    }
}




/*-------------*/

.box-inner > .form-group {
    display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	
	> div  {
	margin: calc(var(--global-spacing)/4);
	
	/*&:first-child {
		margin-left: 0;
		}
	
	
	&:last-child {
		margin-right: 0;
		}
		*/
	}
    
}

.dropdown {
	position: relative;
    display: inline-block;
    cursor: pointer;


}
.dropdown-group .dropdown:last-child {
	margin-right: 0;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0;
    z-index: 1;
    overflow: auto;
    max-height: 80vh;

}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown > span {

	background: #e7e7e7;
    /* height: 100%; */
    display: flex;
    justify-content: left;
    align-items: center;
    
    padding: 0 calc(var(--global-spacing)*2) 0 var(--global-spacing);
    color: #222;
    font-size: 0.8em;
	height: 38px;

}
.dropdown:hover > span {
 background: #e0e0e0;
 
}

.dropdown > span:after {
	content: "";
    display: inline-block;
    margin-bottom: 1.25px;
    border-width: 0 10px 10px 0px;
    padding: 3px;
    margin-left: 2px;
    margin-right: 10px;
    -webkit-transform: rotate(-45deg);
    border: solid #626262;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    right: 5px;
    margin-top: 2px;
}



.dropdown:hover > span:after {
transform: rotate(45deg);
margin-top: 0px;
right: 5px;
}

.dropdown > span > div {
	/*background: red;*/
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-content > span {
    padding: 5px 20px;
    display: block;
    color: black;
    text-align: left;
    /* background: red;*/
    background: white;
    border-bottom: 1px solid #eee;
    font-size: 0.8em;
    /*text-transform: uppercase;*/
	white-space: nowrap;
}
.dropdown-content {
	> span {
		&:hover, &.active {
		background: #eee;
		}
		
		&:last-child {
		border-bottom: 0;
		}
	}
} 

/*--------------*/


.drag-section {

    position: relative;
    /*width: 225px;
    height: 225px;*/
    margin: 0px;
    background: none;
    border: none;

    color: white;
    padding: 0;
    box-sizing: content-box;
    border: 2px solid transparent;
    margin: auto;

        &::before,
        &::after,
        > .drag-container::before,
        > .drag-container::after {
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            }

        &::before {
        top:  calc(var(--border-width) * -1);
        left:  calc(var(--border-width) * -1);
        border-top: var(--border-width) solid #158796;
        border-left: var(--border-width) solid #158796;
        }

        &::after {
        top:  calc(var(--border-width) * -1);
        right:  calc(var(--border-width) * -1);
        border-top: var(--border-width) solid #158796;
        border-right: var(--border-width) solid #158796;

        }

        > .drag-container::before {
        bottom: calc(var(--border-width) * -1);
        left:  calc(var(--border-width) * -1);
        border-bottom: var(--border-width) solid #158796;
        border-left: var(--border-width) solid #158796;
        transition: 0.5s all;
        }

        > .drag-container::after {
            bottom:  calc(var(--border-width) * -1);
            right:  calc(var(--border-width) * -1);
            border-bottom: var(--border-width) solid #158796;
            border-right: var(--border-width) solid #158796;
            transition: 0.5s all;
        }

    .drag-container {
        img {
           max-width: 250px;
            max-height: 250px;
            margin: auto;
            display: block;
        }
    }

}
/*-------------*/
.w-150px {
    width: 150px !important;
}

/*-------------*/

@media screen and (max-width: 1280px) {
  /*.logo {
    background-size: contain;
  }
  .edition {
	  font-size: 9px;
  }*/

  
  body {
	  padding: 0;
  }
  .wrapper, .wrapper .inner {
		position: relative;
	}
}

/*-------------*/
/* clubs details */
/* https://stackoverflow.com/questions/55405044/how-to-build-a-responsive-css-grid-with-square-items-to-display-images-with-mult */

.club-details .club-logo {
    > .button {
		position: absolute;
		bottom: 0;
		right: 0;
	}
    .big {
	max-width: 250px;
        display: block;
        margin: auto;
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}


.club-players {
    height: 250px;
	
	.grid {
	  display: grid;
	  grid-template-columns: repeat(auto-fit, minmax(calc(100% / 7), 1fr)); /* 7 pics per row */
	  grid-gap: 0px;
	  height: 100%;
      /*  max-height: 200px;*/
	
		> div {

		  position: relative;
		  padding-top: 100%; /* added */
		  border: 0px solid;
		 
		
			img {
			  display: block;
			  object-fit: cover;
			  width: 100%;
			  height: 100%; /* image fills the grid item */
			  position: absolute; /* position absolutely */
			  top: 0;
			  left: 0;
			-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
			filter: grayscale(100%);
			opacity: 0.4;
                max-width: 250px;
                max-height: 250px;
			}

		}

	}
	

	
	/*@include media-breakpoint-up(lg) {
	> div {
		background: red;
		}
	}*/
}


.rounded-button {
    background: rgba(0,0,0,.5);
    padding: 1rem 3em;
    border-radius: 2.5rem;
    border: 0.2rem solid rgba(255,255,255,.5);
	
	&.club-members {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
}



/* --------------------- */

.select {
    width: 140px;
    min-width: 140px;
    color: black;
    > div {
        border-radius: 0;
        border: none;

        > div   {
            padding: 0
        }
    }
}

.select__control {
    color: black;
}

.select__value-container {
    color: black;

    > div {
       padding-top: 0;
        padding-bottom: 0;
        padding-left: 15px;
        font-size: 0.9em;
        margin: 0;
    }
}

.select__single-value {
	color: black;
}

.select__indicators {
    color: black;
}


.select__menu {
   font-size: 0.8em;
    margin-top: 0 !important;

}

.select__menu-list {
    color: black;
}

.select__option {
    color: black;
}




.action {
    .select {
      border: 2px solid #ffa500 !important;
      cursor: pointer;
      /*min-width: 160px;*/
      .select__control {
        background: #ffa50066;
      }
      .select__placeholder {
        color: #fff;
      }
      .select__dropdown-indicator {
        color: #fff;
      }
      .select__input {
        color: #fff;
      }
        .select__value-container > div {
            color: #fff;
        }
    }
  }


section.box.clubs-filters .select {
    width: 180px;
}
/* ---------------------- */

.bg-deep-dark {
  background: #091519 !important;
}

.bg-black {
  background: #000000 !important;
    &.text {
        padding: 5px 10px;
    }
}

.bg-black-25 {
  background: #00000025 !important;
     &.text {
        padding: 5px 10px;
    }
}
/* ---------------------- */
#sharing-url-input {
    background: #4d5c60;
    color: white;
    &::placeholder {
         color: white;
    }
}

/* ---------------------- */

.share-link {
    color:white !important;
    background: #0c1c20 !important;
}

/* ---------------------- */

iframe.rankings-embed {
    @include media-breakpoint-up(md) {
        width: 18em;
    }

    min-height: 62vh;
    background: rgba(0,0,0,.5);
    padding: calc(var(--global-spacing)/4);
    max-width: 470px;
    margin: auto;
    display: block;
    border-radius: 15px;
}
/* ---------------------- */

ul.diamonds {
    li {

        line-height: 2em;
        &:before {
            padding-right: 5px;
            content: "\2666";
            color: #11b7ce;
        }
    }
}
/* ---------------------- */

.animated-border { /* used in registation, join-now, etc */
    display:block;
    position: absolute;
    background: var(--animated-border-bg);
    text-align:center;
    width: 75%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    padding: 10px 20px;
    color: var(--animated-border-textcolor);

        &::before, &::after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          border: 2px solid transparent;
          width: 0;
          height: 0;
        }
        &::before {
          top: 0;
          left: 0;
          border-top-color: var(--animated-border);
          border-right-color: var(--animated-border);
          animation: border 2s infinite;
        }
        &::after {
          bottom: 0;
          right: 0;
          animation: border 2s 1s infinite, borderColor 2s 1s infinite;
        }

        @keyframes border {
          0% {
            width: 0;
            height: 0;
          }
          25% {
            width: 100%;
            height: 0;
          }
          50% {
            width: 100%;
            height: 100%;
          }
          100% {
            width: 100%;
            height: 100%;
          }
        }
        @keyframes borderColor {
          0% {
            border-bottom-color: var(--animated-border);
            border-left-color: var(--animated-border);
          }
          50% {
            border-bottom-color: var(--animated-border);
            border-left-color: var(--animated-border);
          }
          51% {
            border-bottom-color: transparent;
            border-left-color: transparent;
          }
          100% {
            border-bottom-color: transparent;
            border-left-color: transparent;
          }

        }
}
/* --------------------- */
.bread-crumb {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    display:flex;
    justify-content: space-between;

    > span {
        font-size: 1.2em;
        display:flex;
        a {
            color: #127d8a;
            text-decoration: underline;
        }

        >  * {
            margin: 0 10px 0 0;
        }

        .isotype {
        width: 50px;
        filter: grayscale(1);
        }

        .icon.chips {
            background-image: url(/assets/img/crm/icon-chips.png);
            margin-left: 0px;
            width: 36px;
            margin-right: 10px;
        }
    }

}
/* ---------------------- */
/* CLUBS -> Pestaña Home */
.club-home {
    .club-home-tos {
        .text-block {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            margin-top: 20px;
            padding-right: 20px;
            margin-right: 20px;
            /*text-align: justify;*/
        }

    }

    .join-now {
        position: relative;
        height: 100px;
        display: block;
        width: 200px;
        margin: auto;
        text-transform: uppercase;
    }
}


/*
li:before {
    padding-right: 5px;
    content: "\2666";
    color: #11b7ce;
}
*/

/* ---------------------- */

.time-counters {
    // border: 1px solid;
    height: 100%;
    min-height: 300px;

    .msg {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        // background: var(--body-background);
        // padding: 0px 20px;

    }
}

.text-yellow {
    color: #e5fb6c;
}

.yellow-border {
    border: 3px solid #e6fb6c;
    box-shadow: 0 0 15px #e6fb6c;
}

.button-green {
    background: #006241 !important;
    border: 2px solid #1cfff4;
    min-width: 240px;
    min-height: 48px;
    display: inline-grid;
    font-family: 'FuturaBTBoldCondensed', sans-serif;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1em;
    text-align: center;
	margin: 10px;
    &:hover {
        background: #1cfff4 !important;
    }
}

.button-orange {
    background: #f67700 !important;
    border: 2px solid #ffca0e;
    min-width: 240px;
    min-height: 48px;
    display: inline-grid;
    font-family: 'FuturaBTBoldCondensed', sans-serif;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1em;
    text-align: center;
	margin: 10px;
    &:hover {
        background: #ffca0e !important;
    }
}

.button-red {
    background: #620021 !important;
    border: 2px solid #ff1a26;
    min-height: 48px;
    display: inline-grid;
    font-family: 'FuturaBTBoldCondensed', sans-serif;
    font-weight: bold;
    letter-spacing: -0.5px;
    font-size: 1em;
    text-align: center;
	margin: 10px;

    &:hover {
        background: #ff1a26 !important;
    }
}

.lobby-info {
    background-color: #025864;
    line-height: 40px;
    // height: 40px !important;
    align-self: normal;
}

.lobby-info-detail {
    align-items: center;
    background-color: #002831;
    // height: 40px !important;
}

.tournament-lobby-logo {
    &.cashbox {
        background-image: url('/assets/img/crm/cash-box-yellow.png');
        width: 66px;
        height: 60px;
        margin-top: -24px;
    }
    &.link {
        background-image: url('/assets/img/crm/link-yellow.png');
        width: 65px;
        height: 65px;
        margin-top: -24px;
    }
    &.poker-table {
        background-image: url('/assets/img/crm/table-yellow.png');
        width: 69px;
        height: 57px;
        margin-top: -17px;
    }
    &.timer {
        background-image: url('/assets/img/crm/timer-yellow.png');
        width: 69px;
        // height: 69px;
        margin-top: -24px;
    }

    &.bright-table {
        background-image: url('/assets/img/crm/poker-table-bright.png');
        width: 100%;
        max-width: 434px;
        height: 280px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: center;
        display: flex;
    }
    &.dark-table {
        background-image: url('/assets/img/crm/poker-table-dark.png');
        width: 100%;
        max-width: 454px;
        height: 310px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-y: center;
        display: flex;
        
    }
}

.tournament-lobby {
    flex-grow: 1;
    .time-counters .msg {
        font-family: "FuturaBTBoldCondensed", sans-serif;
        // background: #000;
    }
    .separator > span {
        background: #000;
    }


    &.players {
        position: relative;
        width: 100%;
        padding-top: 44.5%;
        margin: auto;

        & > div {
            position: absolute;
            transform: translate(-50%, -50%);            

            &.center {
                left: 51%;
                top: 43%;
            }

            &.avatar {
                border-radius: 50%;
                width: 15%;
                padding-top: 13.2%;
                border: 4px solid white;
                overflow: hidden;

                &.position-1 {
                    left: 50%;
                    top: 94%;
                }
                &.position-2 {
                    left: 25%;
                    top: 87%;
                }
                &.position-3 {
                    left: 2%;
                    top: 46%;
                }
                &.position-4 {
                    left: 18%;
                    top: 8%;
                }
                &.position-5 {
                    left: 40%;
                    top: 0%;
                }
                &.position-6 {
                    left: 60%;
                    top: 0%;
                }
                &.position-7 {
                    left: 82%;
                    top: 8%;
                }
                &.position-8 {
                    left: 98%;
                    top: 46%;
                }
                &.position-9 {
                    left: 75%;
                    top: 87%;
                }
            }

            
            
            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%; /* image fills the grid item */
                position: absolute; /* position absolutely */
                top: 0;
                left: 0;

            }

            .name {
                position: absolute;
                font-size: 0.8em;
                background: #00000050;
                padding: calc(var(--global-spacing)/2);
                width: 100%;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow:ellipsis;
                height: 20%;
                bottom: 0%;
                line-height: 20%;
                text-align: center;
            }
        }
    }

    .time-counters {


        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            font-family: 'Roboto Condensed', sans-serif;

        }
        .time1 {
            color: #222;
            background: #ffb500d9;
            max-width: 200px;
            max-height: 200px;
            border-radius: 50%;
            text-align: center;
            transform: translate(-20%,-50%);
            width: 11vw;
            height: 11vw;
            font-size: 1.4em;
            border: 10px solid #ffb500f0;


            > div {
                position: absolute;
                width: 100%;

            }
            .title {

                top: 10%;
                 font-size: 1.1em;

            }
            .clock {
                top: 31%;
                 font-size: 1.35em;
                font-family: 'Orbitron', sans-serif;
            }
            .line1 {

                top: 60%;
                 font-size: 0.9em;
            }
            .line2 {

                top: 75%;
                font-size: 0.9em;
            }
        }
        .time2 {
            color: #444;
            background: grey;
             border: 10px solid #00000020;
            max-width: 150px;
            max-height: 150px;
            border-radius: 50%;
            text-align: center;
            transform: translate(-110%,-50%);
            width: 8vw;
            height: 8vw;


            > div {
                position: absolute;
                width: 100%;

            }
            .title {

                top: 25%;
                 font-size: 1.2em;
                font-weight: bold;

            }
            .line1 {

                top: 50%;
                 font-size: 0.9em;
            }
            .line2 {

                top: 65%;
                font-size: 0.9em;
            }
        }

    }

    .dark-blue-grad {
        background-image: url('/assets/img/crm/dark-blue-grad.png');
        background-repeat: repeat-y;
        padding: 5px;
    }

}

@include media-breakpoint-down(md) {

    .tournament-lobby {

        .time-counters {
            min-height: 400px;

            .time1 {
                max-width: 500px;
                max-height: 500px;
                width: 30vw;
                height: 30vw;
            }

            .time2 {
                max-width: 450px;
                max-height: 450px;
                width: 20vw;
                height: 20vw;
            }
        }
    }
}

/* ---------------------- */

  .separator {
        position: relative;
        overflow: hidden;
        padding: 20px 0;
        margin: 0;
        min-height: 20px;

        &:before {
            content: "";
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(0, -100%);
            height: 100%;
            border: none;
            border-bottom: 1px solid rgba(255,255,255,.2);
        }

        &.full-width {
            margin-left: -100%;
            margin-right: -100%;
        }


        > span {
            margin: auto;
            padding: 0 20px;
            padding: 0 var(--global-spacing);
            display: table;
            background-color: var(--body-background);
            z-index: ¡;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .dotted-text {

        /*display: inline;*/


    position: relative;
    background-image: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 0%);
    background-position: 0 calc(100% - 0.2em);
    background-size: 7px 2px;
    background-repeat: repeat-x;
    white-space: nowrap;


       /* &:before {
            content:"";
            width: calc(100% - 30px);
            border-bottom: 1px dotted white;
            position: absolute;
            bottom: 2px;
            text-align: right;
            z-index: 0;
        }
         */

       /* &:after {
            content: ":";
            width: 8px;
            position: absolute;
            bottom: 1px;
            text-align: right;
            z-index: 0;
            right: 0;
             background: var(--body-background);

        }*/


        span {
            padding-bottom: 5px;
            background: var(--body-background);
            margin-left: -15px;
            padding-left: 15px;
        }


    }


/* ---------------------- */


.rectangle {
  width: 11px;
  height: 24px;
  display: inline-block;
  margin: 0 1px;

  &.filled {
    background: #127d8a;
  }
  &.unfilled {
    background: #073237;
  }
}

.save.active {
    transition: all 0.5s;
    //  -webkit-animation: load7 0.5s 1 ease-in-out;
    animation: load7 0.5s 1 ease-in-out;

}


@keyframes load7 {
    0% {
         box-shadow: 0 0 0px 0;
    }

  50% {
    box-shadow: 0 0 100px 10px orange;
  }
    100% {
         box-shadow: 0 0 0px 0;
    }


}




body { font-size: 20px; }
@include media-breakpoint-down(xl) { body { font-size: 18px } }
@include media-breakpoint-down(lg) { body { font-size: 16px } }
@include media-breakpoint-down(md) { body { font-size: 15px } }
@include media-breakpoint-down(sm) { body { font-size: 14px } }


/* ------------- */
body.page-iframe,
body.page-iframe .wrapper {
    background: none;
    overflow: hidden;
}

body.page-iframe:before,
body.page-iframe header,
body.page-iframe footer {
    display:none;
}

body.page-iframe .iframe-buttons {
    border: 2px solid red;
    border-radius: 5px;
    height: 30px;
    line-height: 30px;


    > div  {

    height: 26px;
    cursor: pointer;
    text-align: center;
    /*background-size: contain;*/
    border-left: 2px solid red;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px !important;
    background-position: 5px;
    padding-left: 20px;
    color: red;


    &:first-child {
        border-left: none;
    }

    &.play {
            background-image: url('/assets/img/crm/play0-red.png');
    }

    &.pause {
            background-image: url('/assets/img/crm/play-red.png');
    }

    &:hover {
                /*background-image: url('/assets/img/crm/play-red.png');*/
                background-color: #ff000090;
                color: white;
                /*color: red;*/

            &.play {
            background-image: url('/assets/img/crm/play0-white.png');
            }
            &.pause {
            background-image: url('/assets/img/crm/play-white.png');
            }

        }



      /* &.status {
            background-image: url('/assets/img/crm/play-white.png');



            &.active {
                background-image: url('/assets/img/crm/play0-red.png');

                color: red;
                background-color: transparent;

                &:hover {
                background-image: url('/assets/img/crm/play0-white.png');
                color: white;
                background-color: #ff000090;
                }
            }

        }

        &.broadcast {
            background-image: url('/assets/img/crm/announcement-red.png');
            background-size: 30px !important;
            padding-left: 25px;
            &.active, &:hover {
                background-image: url('/assets/img/crm/announcement-white.png');
            }
        }
         &.active, &:hover {
            background-color: none;
        }*/
    }
}

/*
.neonbg1 {
           background-image: url(/assets/img/crm/home-emojis-bg.png);
        }
.neonbg2 {
           background-image: url(/assets/img/crm/home-emojis.png);
        }*/
