
@font-face {
    font-family: "FuturaMdBT";
    src: local("FuturaMdBT"),
        url("./assets/fonts/futura-bold-bt.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPTCondBook";
    src: local("FuturaPTCondBook"),
        url("./assets/fonts/futura-pt-cond-book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaMdCnBT";
    src: local("FuturaMdCnBT"),
        url("./assets/fonts/futura-medium-condensed-bt.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "FuturaCondensedBold";
    src: local("FuturaCondensedBold"),
        url("./assets/fonts/futura-condensed-bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaMedium";
    src: local("FuturaMedium"),
        url("./assets/fonts/Futura-Medium.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaBTBoldCondensed";
    src: local("FuturaBTBoldCondensed"),
        url("./assets/fonts/FuturaBT-BoldCondensed.ttf") format("truetype");
    font-weight: bold;
}
